/*---------- RESET ----------*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}
article,
aside,
footer,
header,
hgroup,
nav,
section,
figure,
figcaption {
  display: block;
  margin: 0;
}
* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
input,
textarea {
  -webkit-border-radius: 0;
  border-radius: 0;
}
input {
  -webkit-appearance: none;
}
body {
  -webkit-text-size-adjust: none;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
:focus {
  outline: 0;
}
::-moz-focus-inner {
  border: 0;
}
ins {
  text-decoration: none;
}
del {
  text-decoration: line-through;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
::-moz-selection {
  background: #84bd00;
  color: #fff;
}
::selection {
  background: #84bd00;
  color: #fff;
}
input[type="checkbox"] {
  -webkit-appearance: checkbox;
}
input[type="radio"] {
  -webkit-appearance: radio;
}

/*---------- FONTS ----------*/

@font-face {
  font-family: "icomoon";
  src: url("../public/fonts/icomoon.eot?rwocr2");
  src: url("../public/fonts/icomoon.eot?rwocr2#iefix")
      format("embedded-opentype"),
    url("../public/fonts/icomoon.ttf?rwocr2") format("truetype"),
    url("../public/fonts/icomoon.woff?rwocr2") format("woff"),
    url("../public/fonts/icomoon.svg?rwocr2#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-fb:before {
  content: "\e900";
}
.icon-ig:before {
  content: "\e901";
}
.icon-twitter:before {
  content: "\e902";
}
.icon-whatsapp:before {
  content: "\e903";
}
.icon-arrow-left:before {
  content: "\e904";
}

/*---------- GENERAL ----------*/

html,
body {
  height: 100%;
}
body {
  color: #333;
  background-color: #fff;
  font-family: "Montserrat", Arial, sans-serif;
  font-size: 18px;
}
h1,
h2,
h3,
h4 {
  font-weight: normal;
  max-width: 390px;
  margin: 0 auto;
}
a {
  color: #3142c6;
  text-decoration: none;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
a:hover {
  /* color:#000; */
}
p {
  margin-bottom: 20px;
  line-height: 1.33;
}
.green {
  color: #84bd00;
}
img {
  max-width: 100%;
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
  border: none;
  display: block;
}
.clearfloat {
  zoom: 1;
}
.clearfloat:before,
.clearfloat:after {
  content: "";
  display: table;
}
.clearfloat:after {
  clear: both;
}
.right {
  text-align: right;
}
.centre {
  text-align: center;
  margin: 0 auto;
  /* max-width: 450px; */
}
.tbl {
  display: table;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  position: relative;
}

.wrapper-bg {
  background-color: #ff0000;
  background-position: center;
  margin: auto;
  width: 100%;
  max-width: 480px;
  display: block;
  position: relative;
  /* -webkit-background-size: cover; */
  /* background-size: cover; */
  z-index: 0;
}

.wrapper-bg.first-page:before {
  content: "";
  width: 100%;
  height: 100%;
  z-index: 10;
  position: absolute;
  bottom: 0;
  z-index: 1;
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 20%,
    rgba(0, 0, 0, 1) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 20%,
    rgba(0, 0, 0, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 20%,
    rgba(0, 0, 0, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 );
}

.wrapper-bg-before {
  content: "";
  width: 100%;
  height: 100%;
  z-index: 10;
  position: relative;
  bottom: 0;
  z-index: 1;
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 20%,
    rgba(0, 0, 0, 1) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 20%,
    rgba(0, 0, 0, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 20%,
    rgba(0, 0, 0, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 );
}

/*---------- HEADER ----------*/
.top-bar-yellow {
  background-color: #fed700;
  height: 81px;
  position: relative;
  display: table;
  width: 100%;
  margin: 0 0 25px;
}
.logo {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  margin: 0 auto;
}
.logo img {
  width: 50px;
  margin: 0 auto;
}
.cell {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  /* display: table-cell; */
  /* vertical-align: middle; */
  /* text-align: center; */
}

/*---------- BODY ----------*/
.man-w img {
  width: 350px;
  margin: 0 auto;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.gradient {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
}

/*---------- BODY ----------*/
h1 {
  font-size: 28px;
  line-height: 29px;
  color: #fff;
  letter-spacing: 0;
  font-weight: bold;
  text-align: center;
  font-family: "Montserrat", Arial, sans-serif;
}

h2 {
  font-size: 34px;
  line-height: 40px;
  color: #fff;
  letter-spacing: 0.5px;
  font-weight: bold;
  text-align: center;
  font-family: "Montserrat", Arial, sans-serif;
}

h3 {
  font-size: 19px;
  line-height: 23px;
  color: #fff;
  letter-spacing: 0.5px;
  font-weight: bold;
}

h4 {
  font-size: 41px;
  line-height: 44px;
  color: #fff;
  letter-spacing: 0.5px;
  font-weight: bold;
  display: block;
  text-align: center;
  margin: 0 auto;
}

h4 span {
  display: block;
}

.box-text {
  width: 300px;
}

.start {
  width: 250px;
  position: relative;
  display: block;
  bottom: 20px;
  margin: 15px auto;
  left: 0;
  right: 0;
  z-index: 99;
}
span.box-text.start.first-page {
  position: absolute;
}

.btn {
  border: none;
  text-transform: uppercase;
  display: block;
  height: 70px;
  line-height: 70px;
  /* padding: 12px 71px; */
  color: #f5f5f5;
  font-size: 36px;
  cursor: pointer;
  border-radius: 50px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
  text-align: center;
  margin: 20px auto;
  font-family: "Montserrat", Arial, sans-serif;
}

.btn-primary {
  background-color: #da0000;
  position: relative;
  overflow: hidden;
  text-decoration: none;
  max-width: 300px;
}

.btn span {
  position: relative;
  z-index: 1;
  font-family: "Montserrat", Arial, sans-serif;
}

.btn-primary:hover:after {
  transform: translateY(-45%) skew(20deg) scale(1);
}

.btn-primary:after {
  background-color: #7b1818;
}

.btn-primary:after {
  content: "";
  position: absolute;
  width: 115%;
  height: 200px;
  transform: translateY(-45%) skew(25deg) scale(0);
  left: -5%;
  transition: 200ms ease-in-out;
}

/* This css is for normalizing styles. You can skip this. */

/* The container */
.container-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.container-checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container-checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container-checkbox input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */

/* The container */
.container-radio {
  display: inline-block;
  /* margin: 1px 7px 0; */
  position: relative;
  padding-left: 35px;
  /* margin-bottom: 12px; */
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  font-family: "Montserrat", Arial, sans-ser;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.container-radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  /* border-radius: 50%; */
}

/* On mouse-over, add a grey background color */
.container-radio:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container-radio input:checked ~ .checkmark {
  background-color: #fff;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.container-radio .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container-radio input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container-radio .checkmark:after {
  top: 8px;
  left: 7px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #da0000;
}

.spacing {
  padding-bottom: 10px;
}

.small-spacing {
  padding-bottom: 3px;
}

.padding-top-bottom {
  padding: 25px 0 20px;
}

p {
  font-family: "Montserrat", Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  max-width: 350px;
  margin: 0 auto;
  color: #fff;
}

.frmLead .input-wrapper {
  position: relative;
  overflow: hidden;
  max-width: 300px;
  margin: 0 auto;
}

.frmLead .textbox {
  display: block;
  width: 100%;
  margin: 0;
  border: 0 none;
  background-color: transparent;
  font-size: 18px;
  color: #fff;
  padding: 30px 10px 20px 0;
  overflow: hidden;
}
.frmLead .input-label {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.frmLead .input-label:before,
.frmLead .input-label:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 10px);
  border-bottom: 1px solid #fff;
}

.frmLead .input-label:after {
  margin-top: 2px;
  border-bottom: 3px solid #000;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  -webkit-transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
}

.frmLead .textbox:focus + .input-label:after,
.frmLead .focused .input-label:after {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.frmLead .input-label .label {
  position: absolute;
  left: 0;
  top: 30px;
  bottom: 4px;
  /* height: 38px; */
  /* line-height: 38px; */
  /* margin-bottom: 30px; */
  width: 100%;
  font-size: 15px;
  font-weight: normal;
  padding: 0 50px 0 0;
  text-align: left;
  color: #fff;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.frmLead .textbox:focus + .input-label .label,
.frmLead .input-label.float .label,
.frmLead .focused .input-label .label {
  top: 0;
  font-size: 14px;
  color: #fff;
}

.frmLead .input-wrapper.input-wrapper-select {
  position: relative;
  overflow: visible;
  color: #282828;
  padding-top: 30px;
  text-transform: uppercase;
}

.wrapper-bg.first-page {
  height: 100vh;
}

.pic img {
  max-width: 480px;
  margin: 25px auto 0;
}

.interested .container-radio {
  display: block;
  background-color: #f70000;
  height: 100px;
  line-height: auto;
  text-align: left;
  padding: 29px 0 15px 60px;
}

.interested .container-radio:nth-child(odd) {
  background: #f70000;
  border-radius: 0;
  font-size: 25px;
  /* padding: 10px 0; */
  border-bottom: 2px solid #fff;
}

.interested .container-radio:nth-child(even) {
  background: #a70707;
  border-radius: 0;
  font-size: 25px;
}

.interested .container-radio .checkmark {
  position: absolute;
  top: 32px;
  left: 20px;
  height: 28px;
  /* padding: 0 20px; */
  width: 27px;
  background-color: #eee;
  border-radius: 50%;
  border: 1px solid #000;
}

.love-shape {
  display: block;
  background-image: url(../public/images/love.png);
  background-repeat: no-repeat;
  /* transform: rotate(-22deg); */
  height: 100%;
  position: absolute;
  right: -111px;
  top: 57px;
  width: 394px;
  height: 359px;
  background-size: 100%;
  /* background-color: tomato; */
  z-index: 999999;
}

.wrapper {
  position: relative;
  display: block;
  width: 100%;
  z-index: 999;
  /* height: 100%; */
}

.col {
  display: table-cell;
  vertical-align: middle;
  /* text-align: center; */
}

.height {
  height: 450px;
}

.container {
  display: flex;
  flex-direction: row;
}
.part1 {
  font-weight: 600;
  font-size: 4rem;
  color: #0e76a8;
}
.part2 {
  background-color: #0e76a8;
  border-radius: 5px;
  color: white;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-weight: 600;
  font-size: 4rem;
  margin-left: 0.5rem;
}
.loader {
  position: absolute;
  left: 50%;
  margin-top: 2rem;
  transform: translate(-50%, -50%);
  height: 10px;
  width: 15rem;
  background-color: lightgrey;
  border-radius: 10px;
}
.loading {
  background-color: #000;
  width: 5rem;
  height: 10px;
  border-radius: 10px;
  animation: animation 1.8s infinite;
}

@keyframes animation {
  0% {
    transform: translateX(0rem);
  }
  50% {
    transform: translateX(10rem);
  }
  100% {
    transform: translateX(0rem);
  }
}

.gender-selection h2 {
  line-height: 35px;
  margin-bottom: 4px;
}

span {
  display: block;
}
.white {
  background-color: #fff;
  border-radius: 20px;
  max-width: 450px;
  margin: 0 auto;
  /* margin: 20px; */
  padding: 20px;
  text-align: left;
}

.results .ttle {
  font-size: 21px;
  line-height: 25px;
  color: #000;
  letter-spacing: 0;
  font-weight: bold;
  text-align: left;
  padding: 0 0 20px;
  font-family: "Montserrat", Arial, sans-serif;
}

.results p {
  font-size: 15px;
  line-height: 19px;
  color: #000;
  letter-spacing: 0;
  font-weight: bold;
  text-align: left;
  padding: 0;
  margin: 0 0 15px;
  font-family: "Montserrat", Arial, sans-serif;
}

.results .wrapper-bg {
  height: 100%;
}

.results {
  height: 100%;
}

.chai img {
  max-width: 250px;
  margin: 0 auto 15px;
}

.share-results {
  border-top: 1px solid #707070;
  padding-top: 16px;
}

.share-results .left {
  text-align: left;
}

.share-results .right {
  text-align: right;
}

.share-results .col {
  display: table-cell;
  vertical-align: middle;
  text-align: left;
  padding: 0;
  margin: 0;
}

.social {
  display: block;
  padding: 20px 0;
}

.social > ul {
  font-size: 0;
  display: inline-block;
  padding: 0;
}

.social ul li {
  display: inline-block;
  padding-bottom: 0;
  /* font-size: 16px; */
}

.social a.icon {
  text-decoration: none;
  color: #75bc1e;
  vertical-align: middle;
  display: inline-block;
  /* font-size: 22px; */
  padding: 0 1px 0 8px;
  position: relative;
  /* line-height: 25px; */
  /* background-color: #215A3F; */
}

.social .icon:before {
  color: #fff;
  position: unset;
  /* top: 0; */
  /* bottom: 0; */
  /* left: 0; */
  /* width: 25px; */
  text-align: center;
  /* height: 25px; */
  background-color: #000;
  font-size: 17px;
  padding: 7px;
  /* border-radius: 100%; */
}

.col.left {
  text-align: left;
}

.social a:hover:before {
  color: #fff;
  background-image: linear-gradient(to left, #049774, #44a858 22%, #7db840);
}

.red {
  background-color: #e30000;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  text-transform: unset;
  /* width: 137px; */
  padding: 0;
  margin: 0;
}

.results .btn-primary:before {
  content: "\e904";
  width: 80px;
  height: 30px;
  line-height: 30px;
  position: absolute;
  /* top: 0; */
  /* right: 0; */
  left: 10px;
  text-align: left;
  font-size: 20px;
  /* background-color: #fff; */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.results .wrapper-bg-before {
  height: auto;
}
.black {
  background-color: #000;
  height: 58px;
  line-height: 58px;
  font-size: 22px;
  text-transform: unset;
  z-index: 99999999;
}
